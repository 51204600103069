import classnames from 'classnames';
import CSS from 'csstype';
import * as React from 'react';

import { IMultiCarouselProps } from './multi-carousel.props.autogenerated';

export type IMultiCarouselState = {
    widthContainer: number | null;
    activeSlide: number;
    lastCurrentSlide: number;
    activeIndicator: number;
};

export type slidesType = {
    total: number;
    toShow: number;
    toScroll: number;
};

// =============================================================================
/**
 * MultiCarousel component
 * @extends {React.PureComponent<IMultiCarouselProps<{}>, IMultiCarouselState>}
 */
// =============================================================================
class MultiCarousel extends React.PureComponent<IMultiCarouselProps<{}>, IMultiCarouselState> {

    //==========================================================================
    // VARIABLES
    //==========================================================================

    private container: HTMLDivElement | null;
    private hasSlots: number;
    private slides: slidesType;
    private indicatorsTotal: number;
    private cycleInterval?: ReturnType<typeof setInterval>;
    private scrollThreshold: number;
    private scrollStart: number | undefined;

    //==========================================================================
    // LIFE CYCLE
    //==========================================================================

    constructor(props: IMultiCarouselProps<{}>, state: IMultiCarouselState) {
        super(props);
        this.container = null;
        this.hasSlots = this.props.slots && this.props.slots.slides && this.props.slots.slides.length;
        this.slides = {
            total: this.hasSlots,
            toShow: this._getSlidesToShow(),
            toScroll: this._getSlidesToScroll()
        };
        this.scrollThreshold = 100;

        // If there are more total slides than set slidesToShow, then calculate the number of indicators needed.
        // If there are more slidesToShow than total slides, then all slides will be shown at once,
        // so only 1 indicator is needed.
        this.indicatorsTotal = (this.slides.total > this.props.config.slidesToShow) ? this._calculateIndicators(this.slides) : 1;

        this.state = {
            widthContainer: null,
            activeSlide: 0,
            lastCurrentSlide: this._getLastCurrentSlide(0),
            activeIndicator: 0
        };

        // This-bindings
        this._handleResize = this._handleResize.bind(this);
        this._hoverStart = this._hoverStart.bind(this);
        this._hoverEnd = this._hoverEnd.bind(this);
        this._handleTouchStart = this._handleTouchStart.bind(this);
        this._handleTouchEnd = this._handleTouchEnd.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
    }

    public componentDidMount(): void {
        // Obtain starting carousel container width.
        this._handleResize();

        // Set autoplay interval.
        this._setInterval();

        // Listen to window resize for responsiveness.
        // tslint:disable-next-line: no-typeof-undefined
        if (typeof window !== 'undefined' && window.addEventListener) {
            window.addEventListener('resize', this._handleResize);
            this._handleResize();
        }

        // Add event listener for key presses.
        document.addEventListener('keyup', this._handleKeyPress);
    }

    public componentWillUnmount(): void {
        // Clear autoplay interval.
        this._clearInterval();

        // Clear window resize event listener.
        // tslint:disable-next-line: no-typeof-undefined
        if (typeof window !== 'undefined' && window.addEventListener) {
            window.removeEventListener('resize', this._handleResize);
        }

        // Remove event listener for key presses.
        document.removeEventListener('keyup', this._handleKeyPress);
    }

    public render(): JSX.Element | null {
        const { widthContainer } = this.state;
        const { config, slots } = this.props;
        const { showArrows, showIndicators} = config;
        const widthSlide = widthContainer && this._calculateWidthSlide(widthContainer);
        const widthSlideTrack = widthSlide && this._calculateWidthSlideTrack(widthSlide);
        const readyToRenderSlides = this.hasSlots && widthContainer && widthSlide ? true : false;

        return (
            <div
                className={classnames('multi-carousel', config.className)}
                onMouseEnter={this._hoverStart}
                onMouseLeave={this._hoverEnd}
                onTouchStart={this._handleTouchStart}
                onTouchEnd={this._handleTouchEnd}
            >
                <div className='multi-carousel__slider'>
                    {showArrows && this._renderArrow('left')}
                    <div
                        className='multi-carousel__slider-container'
                        // tslint:disable-next-line:react-this-binding-issue
                        ref={element => this.container = element}
                        style={{width: this._assignWidth(widthContainer)}}
                    >
                        <div
                            className='multi-carousel__slide-track'
                            // tslint:disable-next-line:prefer-object-spread
                            style={{
                                width: Object.assign(
                                {},
                                this._assignWidth(widthSlideTrack),
                                this._assignTransform(this.state.activeSlide, widthSlide)
                            )
                            }}
                        >
                            {readyToRenderSlides && this._renderSlides(slots.slides, widthSlide!)}
                        </div>
                    </div>
                    {showArrows && this._renderArrow('right')}
                </div>
                {showIndicators && this._renderIndicators(this.indicatorsTotal)}
            </div>
        );
    }

    //==========================================================================
    // BASE FUNCTIONALITY
    //==========================================================================

    // Obtain carousel container width pre-render to calculate width of each slide.
    private _handleResize(): void {
        this.container && this.setState({widthContainer: this.container.offsetWidth});
    }

    // If slidesToShow is set to less than or equal to total slides, then return slidesToShow.
    // And if slidesToShow is set to 0, return 1.
    // Otherwise, if total slides is less than slidesToShow, return the total slides instead.
    private _getSlidesToShow(): number {
        if (this.props.config.slidesToShow <= this.hasSlots) {
            return this.props.config.slidesToShow || 1;
        }
        return this.hasSlots;
    }

    // Return set slidesToScroll unless it's 0, then return 1.
    private _getSlidesToScroll(): number {
        return this.props.config.slidesToScroll || 1;
    }

    // Return index of the last slide that is shown on the carousel.
    private _getLastCurrentSlide(activeSlide: number): number {
        return activeSlide + (this.slides.toShow - 1);
    }

    // Calculate width of each slide by dividing the container width by how many slides are shown.
    private _calculateWidthSlide(widthContainer: number): number {
        return widthContainer / this.slides.toShow;
    }

    // Calculate total width of the slide track (even parts not shown) by multiplying slide width by total slides.
    private _calculateWidthSlideTrack(widthSlide: number): number {
        return widthSlide * this.slides.total;
    }

    // Render each slide.
    private _renderSlides(items: React.ReactNode[], widthSlide: number): JSX.Element {
        return (
            <React.Fragment>
                {items.map((slide: React.ReactNode, index: number) => {
                    // Checks if the slide is currently shown on the carousel.
                    const isCurrent = ((index >= this.state.activeSlide) && (index <= this.state.lastCurrentSlide)) ? true : false;
                    // Checks if the slide is the current active slide (first of the shown slides).
                    const isActive = (index === this.state.activeSlide) ? true : false;
                    return (
                        <div
                            key={index}
                            className={classnames(
                                'multi-carousel__slide',
                                {'multi-carousel__slide-current': isCurrent},
                                {'multi-carousel__slide-active': isActive}
                            )}
                            style={{width: this._assignWidth(widthSlide)}}
                        >
                            {slide}
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    // Assign a calculated width to an element via inline-styling.
    private _assignWidth(width: number | null): string | undefined {
        return width ? `${width}px` : undefined;
    }

    // Assign a translateX transformation to the slide track via inline-styling depending on the new active slide.
    private _assignTransform(targetSlide: number, widthSlide: number | null): CSS.Properties | undefined {
        if (widthSlide) {
            const translateValue: number = targetSlide * widthSlide * -1;
            return {transform: `translateX(${translateValue}px)`};
        }
        return undefined;
    }

    //==========================================================================
    // AUTOPLAY
    //==========================================================================

    // If autoplay is enabled, move scroll indicator by +1 at set autoplaySpeed intervals.
    // After reaching last indicator, restart at the first indicator via modulus.
    private _setInterval(): void {
        const { autoplay, autoplaySpeed } = this.props.config;
        if (autoplay && autoplaySpeed) {
            this.cycleInterval = setInterval(
                () => {
                    const nextIndicator = (this.state.activeIndicator + 1) % this.indicatorsTotal;
                    this._handleScrollIndicator(nextIndicator);
                },
                autoplaySpeed
            );
        }
    }

    // Clear autoplay interval.
    private _clearInterval(): void {
        clearInterval(this.cycleInterval!);
    }

    //==========================================================================
    // PAUSE ON HOVER
    //==========================================================================

    // If pauseOnHover is enabled, clear the autoplay interval on carousel's mouseEnter event.
    private _hoverStart(): void {
        if (this.props.config.pauseOnHover) {
            this._clearInterval();
        }
    }

    // If pauseOnHover is enabled, restart the autoplay interval on carousel's mouseLeave event.
    private _hoverEnd(): void {
        if (this.props.config.pauseOnHover) {
            this._setInterval();
        }
    }

    //==========================================================================
    // ARROWS
    //==========================================================================

    // Check if active slide is at the left or right limit in order to disable the corresponding arrow.
    private _checkEnd(direction: string): boolean {
        const leftEnd: number = 0;
        const rightEnd: number = this.slides.total - this.slides.toShow;
        if (
            ((direction === 'left') && (this.state.activeSlide <= leftEnd)) ||
            ((direction === 'right') && (this.state.activeSlide >= rightEnd))
        ) { return true; }
        return false;
    }

    // Check if active slide is near the left or right limit in order to calculate the remainder of slides before
    // reaching the limit.
    private _checkNearEnd(direction: string): boolean {
        const leftNearEnd: number = this.state.activeSlide;
        const rightNearEnd: number = this.slides.total - (this.state.activeSlide + this.slides.toShow);
        if (
            ((direction === 'left') && (leftNearEnd < this.slides.toScroll)) ||
            ((direction === 'right') && (rightNearEnd < this.slides.toScroll))
        ) { return true; }
        return false;
    }

    // Calculate remainder of how much more to scroll on arrow click, so the track slide does not go out of bounds.
    private _calculateRemainder(direction: string): number | void {
        const leftNearEnd: number = this.state.activeSlide;
        const rightNearEnd: number = this.slides.total - (this.state.activeSlide + this.slides.toShow);
        if (direction === 'left') {
            return leftNearEnd % this.slides.toScroll;
        } else if (direction === 'right') {
            return rightNearEnd % this.slides.toScroll;
        }
    }

    // Render each arrow.
    private _renderArrow(direction: string): JSX.Element {
        const isDisabled: boolean = this._checkEnd(direction);
        return (
            <div className='multi-carousel__arrow'>
                <a
                    className={classnames(
                        'multi-carousel__arrow-link',
                        {'multi-carousel__arrow-link-disabled': isDisabled}
                    )}
                    role='button'
                    // tslint:disable-next-line:jsx-no-lambda react-this-binding-issue
                    onClick={() => this._handleArrow(direction)}
                >
                    <span className={classnames('multi-carousel__arrow-icon', `multi-carousel__arrow-${direction}`)} />
                </a>
            </div>
        );
    }

    // If arrow is not disabled, check first if active slide is near end or not.
    // If near end and has a remainder, then handle scroll with the remainder slides.
    // If not near end, then handle scroll with the set slidesToScroll.
    // If near end and no remainder, do nothing (at the limit).
    private _handleArrow(direction: string): void {
        const isNearEnd = this._checkNearEnd(direction);
        const remainder = this._calculateRemainder(direction);
        if (isNearEnd && remainder) {
            this._handleScrollArrow(direction, remainder);
        } else if (!isNearEnd) {
            this._handleScrollArrow(direction, this.slides.toScroll);
        }
    }

    // Set state with new active slide, the last showing current slide, and the active corresponding indicator.
    private _handleScrollArrow(direction: string, slidesToScroll: number): void {
        if (direction === 'left') {
            this.setState(prevState => {
                const newActiveSlide: number = prevState.activeSlide - slidesToScroll;
                return {
                    activeSlide: newActiveSlide,
                    lastCurrentSlide: this._getLastCurrentSlide(newActiveSlide),
                    activeIndicator: prevState.activeIndicator - 1
                };
            });
        } else if (direction === 'right') {
            this.setState(prevState => {
                const newActiveSlide: number = prevState.activeSlide + slidesToScroll;
                return {
                    activeSlide: newActiveSlide,
                    lastCurrentSlide: this._getLastCurrentSlide(newActiveSlide),
                    activeIndicator: prevState.activeIndicator + 1
                };
            });
        }
    }

    //==========================================================================
    // INDICATORS
    //==========================================================================

    // Calculate how many indicators are needed based on how many scrolls it will take to get to the end of
    // the carousel without going out of bounds.
    private _calculateIndicators(slides: slidesType): number {
        const slidesNotShown = Math.max(0, slides.total - slides.toShow);
        return Math.ceil((slidesNotShown / slides.toScroll) + 1);
    }

    // Render each indicator.
    private _renderIndicators(indicatorsTotal: number): JSX.Element {
        const indicatorsArray = [...Array(indicatorsTotal).keys()];
        return (
            <div className='multi-carousel__indicator-container'>
                <ul className='multi-carousel__indicator-list'>
                    {indicatorsArray.map((indicator: number) => {
                        const isDisabled: boolean = (indicator === this.state.activeIndicator) ? true : false;
                        return (
                            <li key={indicator} className='multi-carousel__indicator-item'>
                                <a
                                    className={classnames(
                                        `multi-carousel__indicator-link`,
                                        {'multi-carousel__indicator-link-disabled': isDisabled}
                                    )}
                                    role='button'
                                    // tslint:disable-next-line:jsx-no-lambda react-this-binding-issue
                                    onClick={() => this._handleScrollIndicator(indicator)}
                                >
                                    <span className='multi-carousel__indicator-icon' />
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    // Set state with the clicked indicator, the new active slide, the last showing current slide.
    private _handleScrollIndicator(indicator: number): void {
        if ((indicator + 1) === this.indicatorsTotal) {
            const newActiveSlide: number = this.slides.total - this.slides.toShow;
            this.setState({
                activeSlide: newActiveSlide,
                lastCurrentSlide: this._getLastCurrentSlide(newActiveSlide)
            });
        } else {
            const newActiveSlide: number = indicator * this.slides.toScroll;
            this.setState({
                activeSlide: newActiveSlide,
                lastCurrentSlide: this._getLastCurrentSlide(newActiveSlide)
            });
        }
        this.setState({activeIndicator: indicator});
    }

    //==========================================================================
    // ACCESSIBILITY
    //==========================================================================

    // Detects touch start on mobile touchscreen devices.
    private _handleTouchStart(event: React.TouchEvent<HTMLDivElement>): void {
        if (event.touches.length === 0) {
            this.scrollStart = undefined;
        } else {
            this.scrollStart = event.touches[0].screenX;
        }
    }

    // Calculates the touchscreen swipe direction and distance to determine scroll behavior.
    private _handleTouchEnd(event: React.TouchEvent<HTMLDivElement>): void {
        if (event.changedTouches.length > 0 && this.scrollStart !== undefined) {
            const newTarget: number = event.changedTouches[0].screenX;
            const delta = newTarget - this.scrollStart;

            if (delta > this.scrollThreshold) {
                this._handleArrow('left');
            }

            if (delta < -this.scrollThreshold) {
                this._handleArrow('right');
            }
        }

        this.scrollStart = undefined;

        return;
    }

    // Allows keyboard arrows to scroll through the carousel.
    // tslint:disable-next-line:no-any
    private _handleKeyPress = (event: any) => {
        if (event.keyCode === 37) {
            this._handleArrow('left');
        } else if (event.keyCode === 39) {
            this._handleArrow('right');
        }
    };
}

export default MultiCarousel;
